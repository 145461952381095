<!--
 * @Description: 内容详情展示
 * @Author: 琢磨先生
 * @Date: 2023-01-13 15:55:34
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2024-03-12 18:11:28
-->

<template>
  <div class="box bill_custom_class">
    <el-alert
      type="error"
      title="如若修改订单、费用、补贴等数据则需要重新生成账单！"
      :closable="false"
    ></el-alert>
    <div class="house_no">【{{ model.house_no }}】房</div>
    <div class="info">
      <el-descriptions :column="4">
        <el-descriptions-item label="账期">
          <b>{{ model.month_text }}</b>
        </el-descriptions-item>
        <el-descriptions-item label="营业收入总额(元)">
          <b>￥{{ model.decimal_total_amount }}</b>
        </el-descriptions-item>
        <el-descriptions-item label="费用支出总额(元)">
          <b>￥{{ model.decimal_expense_total_amount }}</b>
        </el-descriptions-item>
        <el-descriptions-item label="实际费用总额(元)">
          <b>￥{{ model.decimal_actual_expense_total_amount }}</b>
        </el-descriptions-item>
        <el-descriptions-item label="状态">
          <el-tag size="small" type="success" v-if="model.status == 40">
            {{ model.status_text }}
          </el-tag>
          <el-tag size="small" type="danger" v-else-if="model.status == 30">
            {{ model.status_text }}
          </el-tag>
          <el-tag size="small" type="warning" v-else-if="model.status == 20">
            {{ model.status_text }}
          </el-tag>
          <el-tag size="small" v-else-if="model.status == 10">
            {{ model.status_text }}
          </el-tag>
          <el-tag size="small" type="info" v-else>
            {{ model.status_text }}
          </el-tag>
        </el-descriptions-item>
        <el-descriptions-item label="创建时间">
          <b>{{ model.create_at }}</b>
        </el-descriptions-item>
      </el-descriptions>

      <el-table :data="incomeTable" border>
        <el-table-column label="对象" prop="name"></el-table-column>
        <el-table-column
          label="分成(元)"
          prop="income_amount"
        ></el-table-column>
        <el-table-column
          label="成本(元)"
          prop="expense_amount"
        ></el-table-column>
        <el-table-column
          label="补贴&扣款(元)"
          prop="subsidy_amount"
        ></el-table-column>
        <el-table-column
          label="实际成本(元)"
          prop="actual_expense_amount"
        ></el-table-column>
        <el-table-column label="转入(元)" prop="in_amount"></el-table-column>
        <el-table-column label="转出(元)" prop="out_amount"></el-table-column>
        <el-table-column label="净收益(元)" prop="profit_amount">
          <template #default="scope">
            {{ scope.row.profit_amount }}
            <el-button
              v-power="'seller/v1/landlordbill/forward'"
              v-if="
                scope.row.type == 1 && model.decimal_landlord_profit_amount < 0
              "
              type="primary"
              link
              size="small"
              @click="openForward"
              >转入</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="data">
      <el-tabs>
        <el-tab-pane label="订单明细">
          <el-table
            :data="model.detail_list"
            border
            show-summary
            :summary-method="orderSummaryMethod"
          >
            <el-table-column
              label="渠道"
              prop="ota_name"
              width="140"
            ></el-table-column>
            <el-table-column label="单号" min-width="200">
              <template #default="scope">
                <el-link
                  type="primary"
                  :underline="false"
                  @click="onOpenOrderDtl(scope.row)"
                  >{{ scope.row.order.order_no }}</el-link
                >
              </template>
            </el-table-column>
            <el-table-column
              label="订单类型"
              prop="order_type_text"
              width="100"
            ></el-table-column>
            <el-table-column
              label="姓名"
              prop="contact_name"
              width="140"
            ></el-table-column>
            <el-table-column label="电话" width="140">
              <template #default="scope">{{ scope.row.mobile_phone }}</template>
            </el-table-column>
            <el-table-column label="订单房费" prop="decimal_amount" width="140">
              <template #default="scope">
                ￥{{ scope.row.decimal_amount
                }}{{ scope.row.amount_tip ? `(${scope.row.amount_tip})` : "" }}
              </template>
            </el-table-column>
            <el-table-column
              label="订单入离日期"
              prop="checkin_date"
              width="200"
            >
              <template #default="scope"
                >{{ scope.row.checkin_date }}~{{
                  scope.row.checkout_date
                }}</template
              >
            </el-table-column>
            <el-table-column label="订单晚间数" width="120" prop="days">
              <template #default="scope">{{ scope.row.days }}晚</template>
            </el-table-column>
            <el-table-column
              label="本月实际房费"
              prop="decimal_month_amount"
              width="140"
            >
              <template #default="scope">
                <el-link
                  type="primary"
                  :underline="false"
                  @click="onOpenDatePrice(scope.row)"
                  >￥{{ scope.row.decimal_month_amount }}</el-link
                >
              </template>
            </el-table-column>
            <el-table-column label="本月实住日期" width="200">
              <template #default="scope"
                >{{ scope.row.start_date }}~{{ scope.row.end_date }}</template
              >
            </el-table-column>
            <el-table-column label="本月实住天数" width="130" prop="month_days">
              <template #default="scope">{{ scope.row.month_days }}晚</template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="费用支出">
          <el-table :data="model.expense_list" border show-summary>
            <el-table-column
              label="项目"
              prop="expense_item_name"
              width="140"
            ></el-table-column>
            <el-table-column
              label="日期"
              prop="date"
              width="120"
            ></el-table-column>
            <el-table-column
              label="金额(元)"
              width="140"
              prop="decimal_total_amount"
            >
              <template #default="scope"
                >￥{{ scope.row.decimal_total_amount }}</template
              >
            </el-table-column>
            <el-table-column
              label="成本(元)"
              width="140"
              prop="decimal_cost_amount"
            >
              <template #default="scope"
                >￥{{ scope.row.decimal_cost_amount }}</template
              >
            </el-table-column>
            <el-table-column
              label="商户(元)"
              width="140"
              prop="decimal_business_amount"
            >
              <template #default="scope"
                >￥{{ scope.row.decimal_business_amount }}</template
              >
            </el-table-column>
            <el-table-column
              label="业主(元)"
              width="140"
              prop="decimal_landlord_amount"
            >
              <template #default="scope"
                >￥{{ scope.row.decimal_landlord_amount }}</template
              >
            </el-table-column>
            <el-table-column label="次数" prop="number">
              <template #default="scope">
                {{ scope.row.number > 0 ? scope.row.number : "--" }}
              </template>
            </el-table-column>
            <el-table-column label="单价" prop>
              <template #default="scope">
                <span v-if="scope.row.unit_price">
                  {{ scope.row.decimal_unit_price
                  }}{{ scope.row.expense_item_unit }}
                </span>
              </template>
            </el-table-column>
            <el-table-column label="来源" width="100">
              <template #default="scope">
                <el-button
                  type="primary"
                  link
                  size="small"
                  @click="onExpenseDtl(scope.row)"
                >
                  {{ scope.row.expense_record_id ? "支出记录" : "固定支出" }}
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="补贴&扣款">
          <el-table :data="model.subsidy_list" border show-summary>
            <el-table-column
              label="类型"
              prop="type_text"
              width="150"
            ></el-table-column>
            <el-table-column
              label="项目"
              prop="expense_item_name"
              width="150"
            ></el-table-column>
            <el-table-column label="金额(元)" prop="decimal_amount">
              <template #default="scope">
                ￥{{ scope.row.type == 10 ? "-" : ""
                }}{{ scope.row.decimal_amount }}
              </template>
            </el-table-column>
            <el-table-column
              label="日期"
              prop="date"
              width="140"
            ></el-table-column>
            <el-table-column
              label="备注"
              prop="remark"
              min-width="200"
            ></el-table-column>
            <el-table-column label="操作">
              <template #default="scope">
                <el-button type="primary" link @click="onSubsidyDtl(scope.row)"
                  >记录详情</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>

        <el-tab-pane label="转入转出">
          <el-table :data="model.forward_list" border show-summary>
            <el-table-column label="类型" width="150">
              <template #default="scope">{{
                scope.row.next_bill_id == model.id ? "转入" : "转出"
              }}</template>
            </el-table-column>
            <el-table-column
              label="转入账期"
              prop="month_cn"
              width="150"
            ></el-table-column>
            <el-table-column
              label="转出账期"
              prop="out_month_cn"
              width="150"
            ></el-table-column>
            <el-table-column label="金额(元)" prop="decimal_amount">
              <template #default="scope"
                >￥{{ scope.row.decimal_amount }}</template
              >
            </el-table-column>

            <el-table-column
              label="备注"
              prop="remark"
              min-width="200"
            ></el-table-column>
            <el-table-column
              label="创建时间"
              prop="create_at"
              width="180"
            ></el-table-column>
            <el-table-column label="操作" width="100">
              <template #default="scope">
                <el-button
                  type="danger"
                  v-if="scope.row.boolCancelable"
                  link
                  @click="onUndoForward(scope.row)"
                  >撤销</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="转账凭证">
          <upload-gallery :count="10" @change="uploadChange"></upload-gallery>
        </el-tab-pane>
        <el-tab-pane label="操作日志">
          <el-timeline>
            <el-timeline-item
              v-for="item in model.log_list"
              :key="item.id"
              :timestamp="`${item.create_at} ${item.emp_name}`"
              placement="top"
            >
              <div class="log_content">{{ item.log_content }}</div>
            </el-timeline-item>
          </el-timeline>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
  <order-dtl
    v-if="current_order_id"
    :order_id="current_order_id"
    @close="current_order_id = ''"
  ></order-dtl>

  <el-dialog
    title="日房费"
    v-model="visibleDatePriceDialog"
    width="600px"
    draggable
  >
    <div class="grid">
      <div class="item" v-for="item in price_list" :key="item">
        <div class="date">{{ item.date }}</div>
        <div class="price">￥{{ item.decimal_price }}</div>
      </div>
    </div>
  </el-dialog>

  <el-dialog
    title="收益转入下期账单"
    v-model="visible"
    width="600px"
    draggable
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <div style="margin-bottom: 20px">
      将业主进收益：￥{{ model.decimal_landlord_profit_amount }}
      转入下期账期!
    </div>
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      label-width="80px"
      :inline="false"
    >
      <el-form-item label="账期" prop="month">
        <el-date-picker
          v-model="form.month"
          type="month"
          placeholder
          value-format="YYYY-MM-DD"
          format="YYYY年MM月"
        ></el-date-picker>
      </el-form-item>
      <el-form-item prop="remark">
        <el-col :span="20">
          <el-input
            v-model="form.remark"
            type="textarea"
            :rows="4"
            placeholder
          ></el-input>
        </el-col>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button type="primary" :loading="saving" @click="forwardSubmit"
        >确定</el-button
      >
    </template>
  </el-dialog>

  <expense-record-dtl
    v-if="curt_expense_dtl"
    :id="curt_expense_dtl.expense_record_id"
    @close="curt_expense_dtl = null"
  ></expense-record-dtl>
  <expense-config-dtl
    v-if="curt_expense_dtl"
    :id="curt_expense_dtl.expense_config_id"
    @close="curt_expense_dtl = null"
  ></expense-config-dtl>
  <subsidy-dtl
    v-if="curt_subsidy_dtl"
    :id="curt_subsidy_dtl.subsidy_id"
    @close="curt_subsidy_dtl = null"
  ></subsidy-dtl>
</template>

<script>
import OrderDtl from "../../trade/order/order_dtl.vue";
import ExpenseRecordDtl from "./dtl_page_exp_record_dtl.vue";
import ExpenseConfigDtl from "./dtl_page_exp_cfg_dtl.vue";
import SubsidyDtl from "./dtl_page_subsidy.vue";
import UploadGallery from "@/views/psd/original_multiple_upload.vue";

export default {
  components: {
    OrderDtl,
    ExpenseRecordDtl,
    ExpenseConfigDtl,
    SubsidyDtl,
    UploadGallery,
  },
  data() {
    return {
      visible: false,
      visibleDatePriceDialog: false,
      //收益表数据
      incomeTable: [],
      //查看订单详情id
      current_order_id: "",
      //订单明细日费用
      price_list: [],
      curt_expense_dtl: null,
      expense_record_id: "",
      expense_config_id: "",
      curt_subsidy_dtl: null,
      //收益转入下期账期表单
      form: {},
      rules: {
        month: [{ required: true, message: "请输入", trigger: "blur" }],
      },
      //进收益转入中
      saving: false,
      //下个月1号
      next_month: new Date(),
      formTransfer: {
        pic_urls: [],
      },
    };
  },
  props: ["model"],
  emits: ["reload"],
  watch: {
    model: {
      handler() {
        if (this.model) {
          this.formTransfer = {
            id: this.model.id,
            pic_urls: this.model.transfer_pic_urls | [],
          };
          this.incomeTable = [
            {
              type: 0,
              name: "商户",
              income_amount: `￥${this.model.decimal_business_income_amount}`,
              expense_amount: `￥${this.model.decimal_business_expense_amount}`,
              subsidy_amount: "-",
              actual_expense_amount: `￥${this.model.decimal_business_actual_expense_amount}`,
              profit_amount: `￥${this.model.decimal_business_profit_amount}`,
              in_amount: "-",
              out_amount: "-",
            },
            {
              type: 1,
              name: "业主",
              income_amount: `￥${this.model.decimal_landlord_income_amount}(${this.model.decimal_income_rate}%)`,
              expense_amount: `￥${this.model.decimal_landlord_expense_amount}`,
              subsidy_amount: `￥${this.model.decimal_landlord_subsidy_amount}`,
              actual_expense_amount: `￥${this.model.decimal_landlord_expense_amount}`,
              profit_amount: `￥${this.model.decimal_landlord_profit_amount}`,
              in_amount: `￥${this.model.decimal_landlord_in_amount}`,
              out_amount: `￥${this.model.decimal_landlord_out_amount}`,
            },
          ];
        }
      },
      immediate: true,
    },
  },
  created() {
    // var dt = new Date();
    // this.next_month = new Date(dt.getFullYear(), dt.getMonth() - 1, 1);
  },
  methods: {
    /**
     * 打开订单详情
     */
    onOpenOrderDtl(item) {
      // console.log(item);
      this.current_order_id = item.order.id;
    },
    /**
     * 显示当月的费用记录
     * @param {*} item
     */
    onOpenDatePrice(item) {
      this.price_list = item.price_list;
      this.visibleDatePriceDialog = true;
    },

    /**
     * 查看费用支出来源详情
     */
    onExpenseDtl(item) {
      this.curt_expense_dtl = item;
      // if(item.expense_config_id){
      //   this.ex
      // }else if(item.expense_record_id){
      //   this.expense_record_id=item.expense_record_id;
      // }
    },

    /**
     * 弹出转入筐
     */
    openForward() {
      this.visible = true;
    },

    /**
     * 打开补贴扣款详情
     */
    onSubsidyDtl(item) {
      this.curt_subsidy_dtl = item;
    },

    /**
     * 负收益转入下期账期提交
     */
    forwardSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.saving = true;
          this.form.bill_id = this.model.id;
          this.$http
            .post("seller/v1/landlordBill/forward", this.form)
            .then((res) => {
              if (res.code == 0) {
                this.$emit("reload");
                this.visible = false;
              }
            });
        }
      });
    },

    /**
     * 撤销转出
     * @param {*} item
     */
    onUndoForward(item) {
      this.$confirm("是否确定要撤销转出？", "温馨提示")
        .then(() => {
          this.$http
            .get("seller/v1/landlordBill/forward/undo?id=" + item.id)
            .then((res) => {
              if (res.code == 0) {
                this.$emit("reload");
              }
            });
        })
        .catch(() => {});
    },

    orderSummaryMethod(e) {
      console.log(e);
      var arr = [];
      arr[0] = "合计";
      arr[1] = `订单总数：${e.data.length}条`;

      var amount = 0,
        days = 0,
        month_amount = 0,
        month_days = 0;
      e.data.forEach((o) => {
        amount = parseFloat(o.decimal_amount) + amount;
        month_amount += parseFloat(o.decimal_month_amount);
        days += o.days;
        month_days += o.month_days;
      });
      arr[5] = `￥${Math.floor(amount * 100) / 100}`;

      arr[7] = days;
      arr[8] = `￥${Math.floor(month_amount * 100) / 100}`;
      arr[10] = month_days;
      return arr;
    },

    /**
     * 图片上传回调
     * @param {*} urls
     */
    uploadChange(file) {
      this.formTransfer.pic_urls.push(file.file_url);
    },
  },
};
</script>

<style scoped>
.box {
  display: flex;
  flex-direction: column;
  height: 100%;
}

/* 房号 */
.box .house_no {
  padding: 10px 0;
  font-weight: bold;
  font-size: 18px;
}

.box .data {
  flex-grow: 1;
  margin-top: 20px;
}

.el-tabs {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.el-tabs__content {
  height: 1px;
  flex-grow: 1;
}
</style>

<style>
.bill_custom_class .el-tabs__content {
  height: 1px;
  flex-grow: 1;
}
.bill_custom_class .el-tabs__content {
  height: 1px;
  flex-grow: 1;
}
.bill_custom_class .el-tab-pane {
  height: 100%;
}
.bill_custom_class .data .el-table {
  height: 100%;
}

/* 房费金额 */
.grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  row-gap: 20px;
}

.grid .item {
  text-align: center;
}
.grid .date {
  color: #999;
}
.grid .price {
  font-size: 16px;
}
</style>
