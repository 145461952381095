<!--
 * @Description: 固定支出详情
 * @Author: 琢磨先生
 * @Date: 2023-01-15 12:37:28
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2023-07-11 10:45:20
-->
<template>
  <el-dialog
    title="固定支出详情"
    v-model="dialogVisible"
    width="700px"
    :close-on-click-modal="false"
    @closed="onClosed"
    draggable
  >
    <div class="wrp" v-loading="loading">
      <el-descriptions :column="2">
        <el-descriptions-item label="类型">{{model.type_text}}</el-descriptions-item>
        <el-descriptions-item label="模式">{{model.mode_text}}</el-descriptions-item>
        <el-descriptions-item label="费用项">{{model.expense_item?.name}}</el-descriptions-item>
        <el-descriptions-item label="金额(元)">
          <b>￥{{model.decimal_amount}}</b>
        </el-descriptions-item>
        <el-descriptions-item label="成本(元)">￥{{model.decimal_cost_amount}}</el-descriptions-item>
        <el-descriptions-item label="数量">￥{{model.number}}</el-descriptions-item>
        <el-descriptions-item label="单价(元)">{{model.decimal_unit_price}}{{model.expense_item?.unit}}</el-descriptions-item>
        <el-descriptions-item label="起止月份">{{model.start_month}}~{{model.end_month}}</el-descriptions-item>
        <el-descriptions-item label="业主占比" v-if="model.type==0">
          <b>{{model.decimal_ratio}}%</b>
        </el-descriptions-item>
        <el-descriptions-item label="备注">{{model.description}}</el-descriptions-item>
        <el-descriptions-item label="创建人">{{model.emp?.chs_name}}</el-descriptions-item>
        <el-descriptions-item label="创建时间">{{model.create_at}}</el-descriptions-item>
      </el-descriptions>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      loading: false,
      model: {},
    };
  },
  props: ["id"],
  emits: ["close"],
  watch: {
    id: {
      handler() {
        if (this.id) {
          this.dialogVisible = true;
          this.loadData();
        }
      },
      immediate: true,
    },
  },
  methods: {
    onClosed() {
      this.$emit("close");
    },

    /**
     * 加载详情
     */
    loadData() {
      this.loading = true;
      this.$http
        .get("seller/v1/expenseConfig/dtl?id=" + this.id)
        .then((res) => {
          if (res.code == 0) {
            this.model = res.data;
          }
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<style   scoped>
.wrp {
  min-height: 400px;
}
</style>